.ta-hidden-input {
    width: 1px;
    height: 1px;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -10000px;
    left: -10000px;
    opacity: 0;
    overflow: hidden;
}

/* add generic styling for the editor */
.ta-root.focussed > .ta-scroll-window.form-control {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.ta-editor.ta-html, .ta-scroll-window.form-control {
    min-height: 300px;
    height: auto;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
}

.ta-scroll-window.form-control {
    position: relative;
    padding: 0;
}

.ta-scroll-window > .ta-bind {
    height: auto;
    min-height: 300px;
    padding: 6px 12px;
}

.ta-editor:focus {
    user-select: text;
}

/* add the styling for the awesomness of the resizer */
.ta-resizer-handle-overlay {
    z-index: 100;
    position: absolute;
    display: none;
}

.ta-resizer-handle-overlay > .ta-resizer-handle-info {
    position: absolute;
    bottom: 16px;
    right: 16px;
    border: 1px solid black;
    background-color: #FFF;
    padding: 0 4px;
    opacity: 0.7;
}

.ta-resizer-handle-overlay > .ta-resizer-handle-background {
    position: absolute;
    bottom: 5px;
    right: 5px;
    left: 5px;
    top: 5px;
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.2);
}

.ta-resizer-handle-overlay > .ta-resizer-handle-corner {
    width: 10px;
    height: 10px;
    position: absolute;
}

.ta-resizer-handle-overlay > .ta-resizer-handle-corner-tl{
    top: 0;
    left: 0;
    border-left: 1px solid black;
    border-top: 1px solid black;
}

.ta-resizer-handle-overlay > .ta-resizer-handle-corner-tr{
    top: 0;
    right: 0;
    border-right: 1px solid black;
    border-top: 1px solid black;
}

.ta-resizer-handle-overlay > .ta-resizer-handle-corner-bl{
    bottom: 0;
    left: 0;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

.ta-resizer-handle-overlay > .ta-resizer-handle-corner-br{
    bottom: 0;
    right: 0;
    border: 1px solid black;
    cursor: se-resize;
    background-color: white;
}

/* copy the popover code from bootstrap so this will work even without it */
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
            box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
.popover.top {
    margin-top: -10px;
}
.popover.bottom {
    margin-top: 10px;
}
.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}
.popover-content {
    padding: 9px 14px;
}
.popover > .arrow,
.popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.popover > .arrow {
    border-width: 11px;
}
.popover > .arrow:after {
    content: "";
    border-width: 10px;
}
.popover.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0;
}
.popover.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0;
}
.popover.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, .25);
}
.popover.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff;
}
